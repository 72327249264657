/**
 * AppEASA
 *
 * @file HistoryPrices.js
 * @version 1.0
 * @description history prices parent component
 *
 */

// Base component
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Redux functions
import { setBreadcrumb } from "../../../actions/breadcrumb";
import { setLoading } from "../../../actions/ui";
import { setSnackbar } from "../../../actions/snackbar";
import { setHistoryTimes, setHistoryPrices } from "../../../actions/historyprices";

// UI Components
import PowerHistoryPricesPanel from "./PowerHistoryPricesPanel";
import GoPricesPanel from "./GoPricesPanel";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";

class HistoryPrices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disaplyKey: "go",
    };
  }

  componentDidMount() {
    this.props.setBreadcrumb([
      {
        label: "Tableau de bord",
        link: "/dashboard",
      },
      { label: "Historique des prix", link: null },
    ]);
  }

  loadSettingContent = (key) => {
    this.props.setHistoryTimes();
    this.props.setHistoryPrices();
    switch (key) {
      case "power":
        return <PowerHistoryPricesPanel />;

      case "go":
        return <GoPricesPanel />;

      default:
        break;
    }
  };

  render() {
    return (
      <Fragment>
        <Container className="main-view-wrapper">
          <Grid container spacing={3} style={{ marginTop: "2rem" }} direction="row" justify="space-between" alignItems="flex-start">
            <Grid item>
              <p className="title1">Prix</p>
            </Grid>
            <Grid item></Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={3} style={{ borderRight: "solid 1px #ccc" }}>
              <Grid container spacing={0} direction="column" justify="flex-start" alignItems="flex-start">
                {/* <Grid item style={{ marginTop: "1rem" }}>
                  <Button
                    color="primary"
                    style={{ padding: "0 10" }}
                    disableElevation
                    variant={this.state.disaplyKey === "display" ? "outlined" : "text"}
                    onClick={(e) => this.setState({ disaplyKey: "power" })}
                  >
                    Electricité
                  </Button>
                </Grid> */}
                <Grid item style={{ marginTop: "1rem" }}>
                  <Button
                    color="primary"
                    style={{ padding: "0 10" }}
                    disableElevation
                    disabled={true}
                    variant={this.state.disaplyKey === "quotation" ? "outlined" : "text"}
                    onClick={(e) => this.setState({ disaplyKey: "go" })}
                  >
                    Garanties d'origines
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={9}>
              {this.loadSettingContent(this.state.disaplyKey)}
            </Grid>
          </Grid>
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  shareholder: state.shareholders.find((s) => s.id === parseInt(props.match.params.id)),
  user: state.user,
  settings: state.settings,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (isLoading) => dispatch(setLoading(isLoading)),
  setBreadcrumb: (breadcrumb) => dispatch(setBreadcrumb(breadcrumb)),
  setSnackbar: (snackbar) => dispatch(setSnackbar(snackbar)),
  setHistoryTimes: (times) => dispatch(setHistoryTimes(times)),
  setHistoryPrices: (prices) => dispatch(setHistoryPrices(prices)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HistoryPrices));
