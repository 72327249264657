/**
 * AppEASA
 *
 * @file GoPricesPanel.js
 * @version 1.0
 * @description go prices history panel
 *
 */

// Base component
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

// import redux functions
import { setLoading } from "../../../actions/ui";
import { setSnackbar } from "../../../actions/snackbar";
import {
  beginLoadHistoryGoTimes,
  setHistoryTimes,
  setHistoryPrices,
  beginLoadHistoryGoPrices,
  getLastGoPricesTime,
  beginSaveGosPrices,
  beginDeleteGosPrices,
} from "../../../actions/historyprices";

// load other libs
import NumberFormat from "react-number-format";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import _ from "lodash";
import "moment/locale/fr";

// load material UI components
import Grid from "@material-ui/core/Grid";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import Link from "@material-ui/core/Link";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Alert from "@material-ui/lab/Alert";

function CurrencyFormat(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      fixedDecimalScale={true}
      decimalScale={3}
      allowNegative={false}
      allowLeadingZeros={false}
      style={{ textAlign: "center", fontSize: "0.8rem" }}
    />
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class GoPricesPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: null,
      selectedTimeId: null,
      lastPricesDate: "",
      addPriceDialogOpen: false,
      isCurrentTime: false,
      selectedTime: "",
      apiStatus: "n/a",
      lastPricesTime: "",
      deletePriceDialogOpen: false,
      years: [],
      gos: [
        {
          key: "hydro_NMS",
          label: "Hydro NMS",
          prices: [],
        },
        {
          key: "hydro_CH",
          label: "Hydro CH",
          prices: [],
        },
        {
          key: "hydro_Alpin",
          label: "Hydro Alpin",
          prices: [],
        },
        {
          key: "hydro_EU",
          label: "Hydro EU",
          prices: [],
        },
        {
          key: "nuclear",
          label: "Nucléaire",
          prices: [],
        },
      ],
    };
  }

  componentDidMount() {
    moment.locale("fr");

    const duration = this.props.config && this.props.config.maxDuration;
    const currentYear = new Date().getFullYear();

    let years = [];
    for (let index = 0; index <= duration; index++) {
      years.push({ year: currentYear + index });
    }

    let today = moment();
    this.setState({ selectedDate: today, years }, () => {
      this.loadAvailableTimes();
    });
  }

  handleDateSelectorChange = (date) => {
    // console.log("handleDateSelectorChange ", moment(date).format("DD/MM/YYYY"));
    this.setState({ selectedDate: date }, () => {
      this.loadAvailableTimes();
    });
  };

  getTimeButtonsGroup = () => {
    return (
      <Grid container direction="row" justify="flex-end" alignItems="center" spacing={0}>
        <Grid item>
          <ButtonGroup size="small" variant="text" color="primary">
            {this.props.historyprices &&
              this.props.historyprices.times &&
              this.props.historyprices.times.map((time) => {
                return (
                  <Button
                    disableElevation
                    key={time.id}
                    variant={time.time === this.state.selectedTime ? "contained" : "outlined"}
                    onClick={(e) => this.handleTimeSelectorChange(time)}
                  >
                    {time.time}
                  </Button>
                );
              })}
            <Button size="small" variant="outlined" color="primary" style={{ float: "right" }} onClick={this.handleAddPriceDialogOpen}>
              Nouveau prix
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    );
  };

  loadAvailableTimes = () => {
    this.props.setLoading(true);
    this.props.setHistoryTimes([]);
    this.props.setHistoryPrices([]);
    this.props
      .beginLoadHistoryGoTimes(moment(this.state.selectedDate).format("YYYY-MM-DD"))
      .then((res) => {
        if (res.length === 0) {
          this.props
            .getLastGoPricesTime()
            .then((res) => {
              this.setState({ lastPricesDate: res.time.date, lastPricesTime: res.time.time });
              this.props.setSnackbar({ open: true, severity: "warning", message: "Aucuns prix disponibles pour la date sélectionnée.", duration: 3000 });
            })
            .catch((err) => {});
        } else {
          this.handleTimeSelectorChange(res.defaultTime);
        }
        this.props.setLoading(false);
      })
      .catch((error) => {
        this.props.setLoading(false);
        this.props.setSnackbar({ open: true, severity: "error", message: "Une erreur s'est produite, réessayez plus tard.", duration: 3000 });
      });
  };

  handleTimeSelectorChange = (time) => {
    this.props.setLoading(true);
    this.props
      .beginLoadHistoryGoPrices(time.id)
      .then(() => {
        this.props.setLoading(false);
        this.setState({ selectedTimeId: time.id, selectedTime: time.time, apiStatus: time.apiCallStatus, isCurrentTime: time.isCurrent });
      })
      .catch((error) => {
        this.props.setLoading(false);
        this.props.setSnackbar({ open: true, severity: "error", message: "Une erreur s'est produite, réessayez plus tard.", duration: 3000 });
      });
  };

  /**
   * Enter new prices
   */

  setUpGosYearlyPrices = () => {
    for (let index = 0; index < this.state.gos.length; index++) {
      const currentGo = this.state.gos[index];
      let prices = [];
      for (let index = 0; index < this.state.years.length; index++) {
        const currentYear = this.state.years[index];
        prices.push({ year: currentYear.year, price: 0 });
      }

      this.setState((prevState) => {
        return {
          ...prevState,
          gos: prevState.gos.map((g) => {
            if (g.key === currentGo.key) {
              return {
                ...g,
                prices: prices,
              };
            } else {
              return g;
            }
          }),
        };
      });
    }
  };

  handleAddPriceDialogClose = () => {
    this.setState({ addPriceDialogOpen: false }, () => this.setUpGosYearlyPrices());
  };

  handleAddPriceDialogOpen = () => {
    this.setUpGosYearlyPrices();
    this.setState({ addPriceDialogOpen: true });
  };

  getGoPriceTable = () => {
    // console.log("years = ", years);
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <p className="text-small bold">Année</p>
            </TableCell>
            {this.state.gos.map((go, index) => {
              return (
                <TableCell key={index} align="center">
                  <p className="text-small bold">{go.label}</p>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.years.map((year) => {
            return (
              <TableRow key={year.year}>
                <TableCell align="left">
                  <p className="text-small bold">{year.year}</p>
                </TableCell>
                {this.state.gos.map((go, index) => {
                  const yearlyPrice = go.prices.find((p) => parseInt(p.year) === parseInt(year.year));
                  //console.log(yearlyPrice);
                  return (
                    <TableCell key={`fieldCell_${index}`} align="center">
                      <TextField
                        id={`energy-price_`}
                        value={yearlyPrice ? yearlyPrice.price : 0}
                        onChange={(e) => this.updateGOPrice(go.key, year.year, e.target.value)}
                        style={{ width: "60px" }}
                        InputProps={{
                          inputComponent: CurrencyFormat,
                        }}
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  };

  updateGOPrice = (key, year, value) => {
    // console.log(`updateGOPrice key=${key} year=${year}  value=${value}`);
    this.setState((prevState) => {
      return {
        ...prevState,
        gos: prevState.gos.map((g) => {
          if (g.key === key) {
            return {
              ...g,
              prices: g.prices.map((p) => {
                if (parseInt(p.year) === parseInt(year)) {
                  return {
                    ...p,
                    price: value === "" || _.isNaN(parseFloat(value)) ? 0 : parseFloat(value),
                  };
                } else {
                  return p;
                }
              }),
            };
          } else {
            return g;
          }
        }),
      };
    });
  };

  saveG0Prices = (e) => {
    this.props.setLoading(true);
    this.props
      .beginSaveGosPrices(this.state.gos)
      .then((res) => {
        this.handleAddPriceDialogClose();
        this.loadAvailableTimes();
        this.props.setLoading(false);
        this.props.setSnackbar({ open: true, severity: "success", message: res.msg, duration: 3000 });
      })
      .catch((err) => {
        this.props.setLoading(false);
        this.props.setSnackbar({ open: true, severity: "error", message: "Impossible d'enregistrer les nouveaux prix.", duration: 3000 });
      });
  };

  handleDeletePriceDialogOpen = () => {
    this.setState({ deletePriceDialogOpen: true });
  };

  handleDeltePriceDialogClose = () => {
    this.setState({ deletePriceDialogOpen: false });
  };

  handleDeltePriceConfirm = () => {
    this.props.setLoading(true);
    this.props
      .beginDeleteGosPrices(this.state.selectedTimeId)
      .then((res) => {
        this.handleDeltePriceDialogClose();
        this.loadAvailableTimes();
        this.props.setLoading(false);
        this.props.setSnackbar({ open: true, severity: "success", message: res.msg, duration: 3000 });
      })
      .catch((err) => {
        this.props.setLoading(false);
        this.props.setSnackbar({ open: true, severity: "error", message: "Impossible de supprimer les prix.", duration: 3000 });
      });
  };

  render() {
    return (
      <Fragment>
        <Grid container spacing={3} direction="row">
          <Grid item xs={12}>
            <p className="title1 bold">Garanties d'origines</p>
          </Grid>
        </Grid>
        <Grid container spacing={3} direction="row" alignItems="flex-end">
          <Grid item xs={3}>
            <p className="text bold" style={{ paddingBottom: "3px" }}>
              Sélectionnez une date
            </p>
          </Grid>
          <Grid item xs={3}>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={"fr"}>
              <Grid container justify="space-around">
                <KeyboardDatePicker
                  format="DD/MM/YYYY"
                  margin="normal"
                  id="date-picker-inline"
                  label="Date"
                  value={this.state.selectedDate}
                  onChange={this.handleDateSelectorChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  orientation="landscape"
                  cancelLabel="Annuler"
                  maxDate={Date(moment())}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid xs={6}>{this.getTimeButtonsGroup()}</Grid>
        </Grid>
        {/* <Grid container spacing={3} direction="row" alignItems="flex-end">
          <Grid item xs={12}>
            {this.getTimeButtonsGroup()}
          </Grid>
        </Grid> */}
        {this.props.historyprices && this.props.historyprices.prices && this.props.historyprices.prices.length > 0 ? (
          <Fragment>
            <Grid container spacing={3} style={{ marginTop: "1rem" }} direction="row" alignItems="center">
              <Grid item xs={3}>
                <p className="text-small">
                  Vérsion de : <span className="bold">{this.state.selectedTime}</span>
                </p>
              </Grid>
              <Grid item xs={3}></Grid>
              <Grid item xs={6}>
                <Grid container direction="row" justify="flex-end" alignItems="center" spacing={0}>
                  <Grid item>
                    <Button size="small" color="secondary" style={{ float: "right" }} onClick={this.handleDeletePriceDialogOpen}>
                      Supprimer
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {this.state.isCurrentTime ? (
              <Grid container spacing={3} style={{ marginTop: "1rem" }}>
                <Grid item xs={12}>
                  <Alert severity="warning">Prix des garanties d'origines actuellement appliqués pour les cotations</Alert>
                </Grid>
              </Grid>
            ) : null}
            <Grid container spacing={3} style={{ marginTop: "1rem" }}>
              <Grid item xs={12}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">
                        <p className="text-small bold">Année</p>
                      </TableCell>
                      <TableCell align="center">
                        <p className="text-small bold">Hydro NMS</p>
                      </TableCell>
                      <TableCell align="center">
                        <p className="text-small bold">Hydro CH</p>
                      </TableCell>
                      <TableCell align="center">
                        <p className="text-small bold">Hydro Alpin</p>
                      </TableCell>
                      <TableCell align="center">
                        <p className="text-small bold">Hydro EU</p>
                      </TableCell>
                      <TableCell align="center">
                        <p className="text-small bold">Nucléaire</p>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.props.historyprices.prices.map((price) => {
                      return (
                        <TableRow key={price.id}>
                          <TableCell align="left" style={{ borderRight: "solid 1px #e0e0e0" }}>
                            <p className="text-small">{price.year}</p>
                          </TableCell>
                          <TableCell align="center" style={{ borderRight: "dotted 1px #e0e0e0" }}>
                            <p className="text-small">
                              <NumberFormat
                                displayType="text"
                                decimalScale={3}
                                fixedDecimalScale={true}
                                value={price.hydro_NMS}
                                prefix=""
                                thousandSeparator="'"
                              />
                            </p>
                          </TableCell>
                          <TableCell align="center" style={{ borderRight: "solid 1px #e0e0e0" }}>
                            <p className="text-small">
                              <NumberFormat
                                displayType="text"
                                decimalScale={3}
                                fixedDecimalScale={true}
                                value={price.hydro_CH}
                                prefix=""
                                thousandSeparator="'"
                              />
                            </p>
                          </TableCell>
                          <TableCell align="center" style={{ borderRight: "dotted 1px #e0e0e0" }}>
                            <p className="text-small">
                              <NumberFormat
                                displayType="text"
                                decimalScale={3}
                                fixedDecimalScale={true}
                                value={price.hydro_Alpin}
                                prefix=""
                                thousandSeparator="'"
                              />
                            </p>
                          </TableCell>
                          <TableCell align="center" style={{ borderRight: "solid 1px #e0e0e0" }}>
                            <p className="text-small">
                              <NumberFormat
                                displayType="text"
                                decimalScale={3}
                                fixedDecimalScale={true}
                                value={price.hydro_EU}
                                prefix=""
                                thousandSeparator="'"
                              />
                            </p>
                          </TableCell>
                          <TableCell align="center">
                            <p className="text-small">
                              <NumberFormat
                                displayType="text"
                                decimalScale={3}
                                fixedDecimalScale={true}
                                value={price.nuclear}
                                prefix=""
                                thousandSeparator="'"
                              />
                            </p>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                <p className="text-small" style={{ marginTop: "5px" }}>
                  Prix en CHF/MWh
                </p>
              </Grid>
            </Grid>
          </Fragment>
        ) : (
          <Fragment>
            <Grid container spacing={3} style={{ marginTop: "2rem" }}>
              <Grid item>
                <p className="text-small italic">
                  Aucune donnée de prix disponible pour la date sélectionnée.
                  {this.state.lastPricesDate ? (
                    <Fragment>
                      Derniers prix enregistrés le{" "}
                      <Link href="#" onClick={(e) => this.handleDateSelectorChange(this.state.lastPricesDate)}>
                        {moment(this.state.lastPricesDate).format("DD MMMM YYYY")}
                      </Link>
                      .
                    </Fragment>
                  ) : null}
                </p>
              </Grid>
            </Grid>
          </Fragment>
        )}
        {/* fullWidth={fullWidth} maxWidth={maxWidth} */}
        <Dialog open={this.state.addPriceDialogOpen} onClose={this.handleAddPriceDialogClose} fullScreen TransitionComponent={Transition}>
          <AppBar style={{ position: "relative" }}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={this.handleAddPriceDialogClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" style={{ flex: 1 }}>
                App EASA
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogTitle id="max-width-dialog-title">Ajout de nouveaux prix pour garanties d'origines</DialogTitle>
          <DialogContent>
            {/* <DialogContentText>Remplissez les champs ci-dessous pour ajouter de noveaux prix concernant les garanties d'origine.</DialogContentText> */}
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {this.getGoPriceTable()}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={this.handleAddPriceDialogClose}>
              Annuler
            </Button>
            <Button color="primary" onClick={this.saveG0Prices}>
              Enregistrer
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.deletePriceDialogOpen}
          onClose={this.handleDeltePriceDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">App EASA : supprimer prix GO</DialogTitle>
          <DialogContent style={{ overflow: "hidden" }}>
            <DialogContentText id="alert-dialog-description">Etes-vous certain de vouloir supprimer les prix affichés ?</DialogContentText>
            {this.state.isCurrentTime ? (
              <Grid container spacing={3} style={{ marginTop: "1rem" }}>
                <Grid>
                  <Alert severity="warning">Vous êtes sur le point de supprimer les prix actuellement utilisés. Les prix précédant seront appliqués.</Alert>
                </Grid>
              </Grid>
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDeltePriceDialogClose} color="primary">
              Annuler
            </Button>
            <Button onClick={this.handleDeltePriceConfirm} color="secondary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  user: state.user,
  historyprices: state.historyprices,
  config: state.config,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (isLoading) => dispatch(setLoading(isLoading)),
  setSnackbar: (snackbar) => dispatch(setSnackbar(snackbar)),
  setHistoryTimes: (times) => dispatch(setHistoryTimes(times)),
  setHistoryPrices: (prices) => dispatch(setHistoryPrices(prices)),
  beginLoadHistoryGoTimes: (date) => dispatch(beginLoadHistoryGoTimes(date)),
  beginLoadHistoryGoPrices: (priceId) => dispatch(beginLoadHistoryGoPrices(priceId)),
  getLastGoPricesTime: (type) => dispatch(getLastGoPricesTime(type)),
  beginSaveGosPrices: (body) => dispatch(beginSaveGosPrices(body)),
  beginDeleteGosPrices: (timeId) => dispatch(beginDeleteGosPrices(timeId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GoPricesPanel);
